import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PhotosCarouselMenu from "../photography/PhotosCarouselMenu"

const IndexPage = () => {
  return (
    <Layout wide>
      <SEO title="Udara's photography" />

      <div className="photography">
        <div className="header">
          <div className="des">A selection of my favourite photos</div>
        </div>
        <div className="photos">
          <PhotosCarouselMenu />
        </div>

        {/* <div className="tools">
          <div className="title">Toolkit</div>
          <div className="cameras">
            <div className="camera">
              <div className="pic">
                <img src="/photography/tools/q2.png" loading="lazy" />
              </div>
              <div className="name">Leica Q2</div>
              <div className="details">28mm F1.7</div>
            </div>
            <div className="camera">
              <div className="pic">
                {" "}
                <img src="/photography/tools/r6.png" loading="lazy" />
              </div>
              <div className="name">Canon EOS R6</div>
              <div className="details">15-35mm RF</div>
            </div>
            <div className="camera">
              <div className="pic">
                <img src="/photography/tools/xt30.png" loading="lazy" />
              </div>
              <div className="name">Fujifilm XT-30</div>
              <div className="details">
                23mm f1.2 <br />
                35mm f2
              </div>
            </div>
            <div className="camera">
              <div className="pic">
                <img src="/photography/tools/dji-pocket.png" loading="lazy" />
              </div>
              <div className="name">Dji Pocket</div>
              <div className="details"></div>
            </div>
          </div>
        </div> */}
      </div>
    </Layout>
  )
}

export default IndexPage
