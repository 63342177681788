import React, { useEffect, useState } from "react"
import * as styles from "./PhotosCarouselMenu.module.scss"
import photographs from "../photographs.json"
import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PhotosCarouselMenu = ({ changeIndex = () => {} }) => {
  const [focused, setFocused] = useState(false)

  const toggleFocus = index => {
    if (focused === index) {
      setFocused(false)
      return
    }

    setFocused(index)
  }

  const urlBuilder = ({ baseUrl }) => {
    return `http://localhost:8000/photography/c/${baseUrl}`
  }

  const renderPhotos = () => {
    return photographs.map((photo, i) => {
      const isLandscape = photo.width > photo.height
      return (
        <div
          className={`${styles.photo} ${focused === i && styles.current} ${
            isLandscape && styles.landscape
          }`}
          // onClick={() => toggleFocus(i)}
        >
          <img
            // onClick={() => changeIndex(i)}
            src={`../photography/c/${photo.src}`}
            loading="eager"
          />
          <div className={styles.meta}>
            <div>
              {photo.city}, {photo.year}
            </div>
            <div>{photo.exif.Model ?? "Fujifilm XT-30"}</div>
          </div>
        </div>
      )
    })
  }

  return <div className={styles.container}>{renderPhotos()}</div>
}

export default PhotosCarouselMenu
